import CustomHead from "../templates/CustomHead";
import React from "react";
import TopNav from "./TopNav";

interface Props {
  fullWidth?: boolean;
  pageName: string;
  children: React.ReactNode;
}
function TopbarLayout(props: Props): JSX.Element {
  return (
    <>
      <CustomHead pageName={props.pageName} />
      <TopNav fullWidth={props.fullWidth} pageName={props.pageName} />
      {props.children}
    </>
  );
}

export default TopbarLayout;
