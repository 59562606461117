import React, { useState } from "react";
import { FolderIcon, NewspaperIcon } from "@heroicons/react/outline";
import Anchor from "components/atoms/Anchor";
import { DebugSwitch } from "components/atoms/Debug";
import ChangeLog from "components/organisms/ChangeLog";
import { withRouter } from "next/router";
import { useAuth } from "lib/use-auth";
import Avatar from "components/atoms/Avatar";
import ProfileDropdown from "components/atoms/ProfileDropdown";
import Button from "@library/Button";

const topLinks = [
  {
    name: "Projects",
    path: "/projects",
    icon: FolderIcon,
  },
  {
    name: "Datasets",
    path: "/data",
    icon: NewspaperIcon,
  },
];

function TopNav({ fullWidth = false, router, pageName = "" }) {
  const { user, loading, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const currentPath = pageName.toLowerCase();
  return (
    <nav className="hl-top-nav sticky top-0 z-50 bg-gray-800">
      <div className={`mx-auto px-16 sm:px-24 lg:px-32 ${fullWidth || "max-w-screen-3xl"}`}>
        <div className="flex h-64 items-center justify-between">
          <div className="flex items-center">
            <Anchor href={user ? "/projects" : "/"}>
              <div className="mt-0 -ml-8 flex-shrink-0 rounded px-4 py-4 pb-0 hover:bg-gray-700">
                {/* <img className="inline-block h-24 pr-8 mb-6" src="/img/loop-white.svg" alt="Humanloop logo" /> */}
                <img className="h-32 w-auto sm:h-32" src="/img/logos/SVG/Humaloop_Logo_Lockup_White.svg" alt="" />
              </div>
            </Anchor>
            <div className="hidden sm:block">
              {user && (
                <div className="ml-40 flex items-baseline">
                  {topLinks.map((link, index) => (
                    <Anchor
                      href={`${link.path}`}
                      key={`toplink-${index}`}
                      className={`${index !== 0 ? "ml-16" : ""} ${
                        router.pathname === link.path ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white"
                      } font-medium ml-16 rounded px-12 py-8 text-sm focus:bg-gray-700 focus:text-white focus:outline-none`}
                    >
                      {link.name}
                    </Anchor>
                  ))}

                  {user.feature_flags?.LLAMA ||
                    (user.role === "admin" && (
                      <Anchor href={"/llama/"} className="rounded px-10 py-4 hover:bg-gray-100 ">
                        🦙
                      </Anchor>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="hidden sm:block">
            <div className="ml-16 flex items-center gap-8 md:ml-24">
              <DebugSwitch />
              <ChangeLog />

              {!loading && user ? (
                <ProfileDropdown />
              ) : (
                <>
                  <Button styling="outline" className=" bg-white" href="/login">
                    Login
                  </Button>
                </>
              )}
            </div>
          </div>

          <div className="-mr-8 flex sm:hidden">
            {/* Mobile menu button  */}
            <button
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              className="inline-flex items-center justify-center rounded p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none"
            >
              {/* Menu open: "hidden", Menu closed: "block" */}
              <svg className={`h-24 w-24  ${menuOpen ? "hidden" : "block"}`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              {/* Menu open: "block", Menu closed: "hidden" */}
              <svg className={`h-24 w-24  ${menuOpen ? "block" : "hidden"}`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`md:hidden ${menuOpen ? "block" : "hidden"}`}>
        <div className="pb-3 px-8 pt-8 sm:px-12">
          {topLinks.map((link, index) => (
            <Anchor
              href={`${link.path}`}
              key={`menu-${index}`}
              className={`${index !== 0 ? "mt-4" : ""} ${
                currentPath === link.path ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white"
              } font-medium block rounded px-12 py-8 text-base   focus:bg-gray-700 focus:text-white focus:outline-none`}
            >
              {link.name}
            </Anchor>
          ))}
        </div>
        {!loading && user ? (
          <div className="pb-3 border-t border-gray-700 pt-16">
            <div className="flex items-center px-20">
              <div className="flex-shrink-0">
                <Avatar user={user} size="md" />
              </div>

              <div className="ml-12">
                <div className="font-medium text-base leading-none text-white">{user ? user.full_name : "Loading..."}</div>
                <div className="font-medium mt-4 text-sm leading-none text-gray-400">{user && user.username}</div>
              </div>
            </div>
            <div className="mt-12 px-8">
              <Anchor
                href="/profile"
                className="font-medium block rounded px-12 py-8 text-base text-gray-400 hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none"
              >
                Your Profile
              </Anchor>
              <a
                href="/api"
                className="font-medium block rounded px-12 py-8 text-base text-gray-400 hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none"
              >
                API docs
              </a>
              <a
                onClick={logout}
                className="font-medium mt-4 block rounded px-12 py-8 text-base text-gray-400 hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none"
              >
                Sign out
              </a>
            </div>
          </div>
        ) : (
          <div className="space-y-8 border-t border-gray-700 px-8 pb-12 pt-8 sm:px-12">
            <Anchor
              href={"/login"}
              className={`
                  ${
                    currentPath === "/login" ? "bg-gray-900 text-white" : "bg-white text-gray-700 hover:bg-gray-700 hover:text-white"
                  } btn btw-white font-medium block rounded px-12 py-8 text-base   focus:bg-gray-700 focus:text-white focus:outline-none`}
            >
              Login
            </Anchor>
          </div>
        )}
      </div>
    </nav>
  );
}

export default withRouter(TopNav);
