import React from "react";
import * as Sentry from "@sentry/nextjs";
import Cohere from "cohere-js";
import { useAuth } from "lib/use-auth";
import { useRouter } from "next/router";
import { ErrorBoundaryProps } from "@sentry/react/types/errorboundary";

export const SentryErrorBoundary = ({ children, ...props }: ErrorBoundaryProps): JSX.Element => {
  const { user } = useAuth();
  const router = useRouter();
  return (
    <Sentry.ErrorBoundary
      showDialog={true}
      fallback={
        <div className="prose prose-blue">
          Something went wrong. Try{" "}
          <a href="#refresh-page" onClick={() => router.reload()}>
            refreshing
          </a>{" "}
          the page. Or{" "}
          <a
            href="#cohere-show"
            onClick={() => {
              Cohere.widget("show");
              Cohere.widget("expand");
            }}
          >
            call us
          </a>{" "}
          to get help.
        </div>
      }
      dialogOptions={{
        // This doesn't seem to show in the dialog, I think because the react hook to get user info has not returned before the error is thrown.
        user: { email: user?.email_address, name: user?.full_name },
      }}
      {...props}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
