import React, { useEffect } from "react";
import { isUndefined } from "lodash";

//Typescript is unaware of the Headway property on window. To fix this, we declare a global by extending window:
declare global {
  interface Window {
    Headway: any;
  }
}

const ChangeLog = () => {
  useEffect(() => {
    var config = {
      selector: "#topnav-headway",
      account: "7eNAXJ",
    };

    // TODO: Investigate whether should re-init whenever rerendered.
    // See: https://docs.headwayapp.co/widget#initializing-widget-programmatically-spa
    // eslint-disable-next-line no-undef
    if (!isUndefined(window?.Headway)) window.Headway.init(config);
  });

  return (
    <button
      className="flex items-center rounded-full border-2 border-transparent p-1 text-gray-400 hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none"
      aria-label="Notifications"
    >
      <div className="" id="topnav-headway"></div>
    </button>
  );
};

export default ChangeLog;
