import { classNames } from "@library/utils/classNames";
import DeviceSupport from "components/atoms/DeviceSupport";
import { LoadingLogo } from "components/atoms/Loading";
import { SentryErrorBoundary } from "@components/error/SentryErrorBoundary";
import PageTitle from "components/molecules/PageTitle";
import TopbarLayout from "components/molecules/TopbarLayout";
import React from "react";

interface Props {
  pageName: string;
  loading?: boolean;
  header?: React.ReactNode;
  description?: React.ReactNode;
  cta?: React.ReactNode;
  children?: React.ReactNode;
  fullScreen?: boolean;
  hideTitle?: boolean;
  loadingPlaceholder?: React.ReactNode;
}

export default function PageLayout({
  pageName,
  loading = false,
  header = null,
  description = null,
  cta = null,
  children = null,
  fullScreen = false,
  hideTitle = false,
  loadingPlaceholder = <LoadingLogo />,
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <div className="h-screen">
      <DeviceSupport />
      <TopbarLayout fullWidth={true} pageName={pageName}>
        {/* Page title is now always full width */}
        {!hideTitle && !header && <PageTitle fullWidth={true} pageName={pageName} description={description} cta={cta} />}
        {header}
        <main className={classNames("v-screen-minus-nav mx-auto p-24 pb-128", fullScreen ? " w-full " : "max-w-screen-3xl ")}>
          <SentryErrorBoundary>{loading ? loadingPlaceholder : children}</SentryErrorBoundary>
        </main>
      </TopbarLayout>
      <DeviceSupport />
    </div>
  );
}
