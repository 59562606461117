import { Avatar as EvergreenAvatar } from "evergreen-ui";
import { classNames } from "lib/utils";
import md5 from "md5";
import React, { memo } from "react";

function gravatarURI(email) {
  const emailHash = md5(email.trim().toLowerCase() || "");
  const fallbackUrl = "https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png";
  return `https://www.gravatar.com/avatar/${emailHash}?d=${encodeURIComponent(fallbackUrl)}`;
}

export interface AvatarUser {
  full_name?: string;
  email_address: string;
}

export type AvatarSize = "xs" | "sm" | "md" | "lg";

interface AvatarProps {
  user?: AvatarUser;
  size?: AvatarSize;
  className?: string;
}

const AVATAR_SIZES: Record<AvatarSize, number> = { xs: 28, sm: 32, md: 40, lg: 56 };

function Avatar({ user, size, className }: AvatarProps): JSX.Element {
  const avatarSize = AVATAR_SIZES[size || "sm"];
  const email = user?.email_address;
  const name = user?.full_name || email;
  const imageURI = gravatarURI(email);
  return <EvergreenAvatar className={classNames("hl-avatar", className)} forceShowInitials={true} size={avatarSize} src={imageURI} name={name} />;
}
const MemoizedAvatar = memo(Avatar);

export default MemoizedAvatar;
