import React from "react";

export default function PageTitle(props: { pageName: string; description: string | Element | React.ReactFragment; cta: React.ReactNode; fullWidth: boolean }) {
  const fullWidth = props.fullWidth || false;

  return (
    <header className="">
      <div className={`mx-auto py-24 ${fullWidth ? "" : "max-w-screen-3xl"} px-24 lg:px-32`}>
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="font-display text-32-32 font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:leading-9">{props.pageName}</h2>
            <div className="mt-4 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
              <div className="mt-8 flex items-center text-base text-gray-600 sm:mr-24">{props.description}</div>
            </div>
          </div>

          <div className="mt-20 flex sm:mt-0 lg:ml-16">{props.cta}</div>
        </div>
      </div>
    </header>
  );
}
