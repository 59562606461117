import { Menu, Transition } from "@headlessui/react";
import { SupportIcon } from "@heroicons/react/outline";
import Cohere from "cohere-js";
import Anchor from "components/atoms/Anchor";
import MemoizedAvatar from "components/atoms/Avatar";
import Avatar from "components/atoms/Avatar";
import { DOCS_LINK } from "lib/constants";
import { useAuth } from "lib/use-auth";
import { classNames } from "lib/utils";
import React, { Fragment } from "react";

export default function ProfileDropdown() {
  const { user, logout } = useAuth();

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                <span className="sr-only">Open user menu</span>
                <Avatar className="flex max-w-xs items-center rounded-full text-sm text-white " user={user} size="sm" />
              </Menu.Button>
            </div>

            <Transition show={open} as={Fragment}>
              <Menu.Items
                static
                // z-50 so this is above the instructions block on task interface
                className="absolute right-0 z-50 mt-8 w-224 origin-top-right rounded bg-gray-100 shadow-c ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="mx-auto w-full rounded-t bg-white py-20 text-center">
                  <MemoizedAvatar user={user} size="lg" />
                  <div className="block px-16 py-8 text-16-24 font-bold text-gray-900 ">{user.full_name}</div>
                  <div className="block truncate px-16 pb-2 text-12-14 text-gray-600">{user.email_address}</div>
                </div>
                <div className="block px-8 pb-2 text-sm text-gray-700 ">
                  {!user?.verified && (
                    <span className="font-medium inline-flex items-center rounded-full bg-yellow-100 px-10 py-2 text-xs leading-4 text-yellow-800">
                      Unverified
                    </span>
                  )}
                </div>

                <div className="py-16 px-12">
                  <Menu.Item>
                    {({ active }) => (
                      <Anchor href="/profile" className={classNames(active ? " text-gray-900" : "text-gray-700", "block px-16 py-8 text-sm")}>
                        User settings
                      </Anchor>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <a href={DOCS_LINK} className={classNames(active ? " text-gray-900" : "text-gray-700", "block px-16 py-8 text-sm")}>
                        Documentation
                      </a>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(active ? " text-gray-900" : "text-gray-700", "inline-flex w-full gap-2 px-16 py-8 text-left text-sm")}
                        onClick={() => {
                          Cohere.widget("show");
                          Cohere.widget("expand");
                        }}
                      >
                        Help
                      </button>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button onClick={logout} className={classNames(active ? " text-gray-900" : "text-gray-700", "flex w-full px-16 py-8 text-left text-sm")}>
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
}
